<template>
  <main 
    v-if="$parent.isAuth"
    class="main profile-page page-inside" 
  >
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="page-inside-wrapper">
          <div class="wrapper">
            <div class="nav-container">
              <div class="title title-main">
                {{ $t('MY PROFILE') }}
              </div>
              <div class="info-list">
                <div class="profile-info">
                  <div class="img-container">
                    <img 
                      src="./../assets/user.svg" 
                      class="img"
                    >
                  </div>
                  <div class="text">
                    <div class="desc">
                      <b>{{ name }} {{ surname }}</b>
                    </div>
                    <div 
                      v-if="steamId"
                      class="info-steam"
                    >
                      <img 
                        src="./../assets/steam2.svg" 
                        class="img"
                      >
                      <div class="desc">
                        {{ $t('Steam ID:') }}
                      </div>
                      <div class="desc">
                        {{ steamId }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="balance-info">
                  <div class="balance">
                    <div class="balance-left">
                      <div class="balance-text">
                        <img 
                          src="./../assets/wallet.svg" 
                          class="img"
                        >
                        <div class="desc">
                          {{ $t('My balance') }}
                        </div>
                      </div>
                      <div class="title">
                        <b>
                          <span class="currency">{{ $parent.currency }}</span>
                          {{ balance }}
                        </b>
                      </div>
                    </div>
                    <button 
                      class="button dark" 
                      @click="$parent.openTopup"
                    >
                      {{ $t('Top up my ballance') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="nav">
                <router-link 
                  to="/profile/settings" 
                  class="item"
                >
                  <div class="desc">
                    <b>{{ $t('Settings') }}</b>
                  </div>
                </router-link>
                <router-link 
                  to="/profile/transactions" 
                  class="item"
                >
                  <div class="desc">
                    <b>{{ $t('Transactions') }}</b>
                  </div>
                </router-link>
                <router-link 
                  to="/profile/orders" 
                  class="item"
                >
                  <div class="desc">
                    <b>{{ $t('Order history') }}</b>
                  </div>
                </router-link>
                <!-- <div 
                  class="item" 
                  @click="$parent.logout"
                >
                  <svg 
                    width="26" 
                    height="26" 
                    viewBox="0 0 26 26" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_48_1166)">
                      <path 
                        fill-rule="evenodd" 
                        clip-rule="evenodd" 
                        d="M12.8138 21.859V23.1548C12.8138 24.1939 12.2959 25.0909 11.3961 25.6104C10.9631 25.8604 10.4799 25.9998 9.97885 26C9.47738 26.0003 8.9943 25.8606 8.56104 25.6104L2.7996 22.284C1.8996 21.7644 1.38184 20.8676 1.38184 19.8284V2.83552C1.38184 1.27192 2.6538 0 4.21736 0H16.9061C18.4698 0 19.7418 1.27182 19.7418 2.83552V6.41758C19.7418 6.93144 19.3246 7.34866 18.8108 7.34866C18.2968 7.34866 17.8799 6.93149 17.8799 6.41758V2.83552C17.8799 2.29861 17.443 1.86174 16.9061 1.86174H5.78746L11.3961 5.10042C12.2956 5.61991 12.8138 6.51671 12.8138 7.55554V19.9971H16.9061C17.443 19.9971 17.8799 19.5605 17.8799 19.0235V15.8834C17.8799 15.3692 18.2965 14.9523 18.8108 14.9523C19.3248 14.9523 19.7418 15.3693 19.7418 15.8834V19.0235C19.7418 20.5872 18.4698 21.859 16.9061 21.859H12.8138ZM21.4407 11.8604L20.4154 12.8857C20.0518 13.2492 20.0519 13.8386 20.4154 14.2022C20.5904 14.3773 20.8262 14.4746 21.0736 14.4746C21.3213 14.4746 21.5569 14.3775 21.7322 14.2022L24.3463 11.5876C24.7098 11.2241 24.7098 10.635 24.3463 10.2715L21.7322 7.6573C21.3686 7.29371 20.7792 7.29386 20.4154 7.65725C20.052 8.02044 20.0521 8.61006 20.4154 8.97335L21.4407 9.99842H14.572C14.0576 9.99842 13.6411 10.4152 13.6411 10.9295C13.6411 11.4439 14.0577 11.8604 14.572 11.8604H21.4407V11.8604Z" 
                        fill="#4E518F"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_48_1166">
                        <rect 
                          width="26" 
                          height="26" 
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <div class="desc">
                    <b>{{ $t('Log out') }}</b>
                  </div>
                </div> -->
              </div>
            </div>
            <div 
              v-if="$route.path == '/profile/settings'"
              class="profile-content profile-content-setings"
            >
              <div class="profile-content-left">
                <div class="form">
                  <div class="desc main-desc">
                    <b>{{ $t('Personal Information') }}</b>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('First name') }}
                    </div>
                    <input 
                      v-model="name"
                      type="text" 
                      :placeholder="$t('First name')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Last name') }}
                    </div>
                    <input 
                      v-model="surname"
                      type="text" 
                      :placeholder="$t('Last name')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('E-mail') }}
                    </div>
                    <input
                      v-model="email"
                      type="email" 
                      :placeholder="$t('E-mail')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Phone') }}
                    </div>
                    <input 
                      v-model="phone"
                      type="tel" 
                      :placeholder="$t('Phone')" 
                    >
                  </div>
                  <div 
                    v-if="countryOptions"
                    class="input-container" 
                  >
                    <div class="desc">
                      {{ $t('Country') }}
                    </div>
                    <select v-model="selectedCountry">
                      <option 
                        value="" 
                        disabled 
                        selected
                      >
                        {{ $t('Countries') }}  
                      </option>
                      <option 
                        v-for="item in countryOptions" 
                        :key="item.id"
                        :value="item"
                      >
                        {{ $t(item.title) }}
                      </option>
                    </select>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('City') }}
                    </div>
                    <input 
                      v-model="city"
                      type="text" 
                      :placeholder="$t('City')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('ZIP Code') }}
                    </div>
                    <input 
                      v-model="zipCode"
                      type="text" 
                      :placeholder="$t('ZIP Code')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Address') }}
                    </div>
                    <input 
                      v-model="address"
                      type="text" 
                      :placeholder="$t('Address')" 
                    >
                  </div>
                  <button 
                    class="button" 
                    @click="updateProfileData"
                  >
                    <span>
                      {{ $t('Save changes') }}
                    </span>
                  </button>
                  <div class="errors">
                    <transition name="fade">
                      <div 
                        v-if="profileUpdateErrorMessaage"
                        class="desc red" 
                      >
                        {{ $t(profileUpdateErrorMessaage) }}
                      </div>
                    </transition>
                    <transition name="fade">
                      <div 
                        v-if="profileUpdateSuccessMessaage"
                        class="desc green" 
                      >
                        {{ $t(profileUpdateSuccessMessaage) }}
                      </div>
                    </transition>
                  </div>
                </div>
                <div class="form trade-link-form">
                  <div class="desc main-desc">
                    <b>{{ $t('Trade link') }}</b>
                  </div>
                  <div class="desc small">
                    {{ $t('*Make sure your Steam inventory is set to public.') }}
                  </div>
                  <div class="input-container">
                    <input
                      v-model="steamLink"
                      type="text" 
                      :placeholder="$t('Enter your Steam trade link')"
                    >
                    <button 
                      class="button" 
                      @click="updateSteamLink"
                    >
                      {{ $t('Add link') }}
                    </button>
                  </div>
                  <div class="errors">
                    <transition name="fade">
                      <div 
                        v-if="errorSteamLink"
                        class="desc red" 
                      >
                        {{ $t(errorSteamLink) }}
                      </div>
                    </transition>
                    <transition name="fade">
                      <div 
                        v-if="successSteamLink"
                        class="desc green" 
                      >
                        {{ $t(successSteamLink) }}
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
              <div class="profile-content-right">
                <div class="form">
                  <div class="desc main-desc">
                    <b>{{ $t('Change password') }}</b>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Current password') }}
                    </div>
                    <input 
                      v-model="curPass"
                      type="text" 
                      :placeholder="$t('Current password')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('New password') }}
                    </div>
                    <input  
                      v-model="newPass"
                      type="text" 
                      :placeholder="$t('New password')" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Confirm new password') }}
                    </div>
                    <input 
                      v-model="repeatPass"
                      type="text" 
                      :placeholder="$t('Confirm password')" 
                    >
                  </div>
                  <button 
                    class="button blue" 
                    @click="updatePassword"
                  >
                    <span>{{ $t('Save changes') }}</span>
                  </button>
                  <div class="errors">
                    <transition name="fade">
                      <div 
                        v-if="passUpdateErrorMessaage"
                        class="desc red"
                      >
                        {{ $t(passUpdateErrorMessaage) }}
                      </div>
                    </transition>
                    <transition name="fade">
                      <div 
                        v-if="passUpdateSuccessMessaage"
                        class="desc green"
                      >
                        {{ $t(passUpdateSuccessMessaage) }}
                      </div>
                    </transition>
                  </div>
                </div>
                <div class="form logout-form">
                  <div class="desc main-desc">
                    <b>{{ $t('Log out of your account') }}</b>
                  </div>
                  <button 
                    class="button dark" 
                    @click="$parent.logout"
                  >
                    {{ $t('Log out') }}
                    <img 
                      src="./../assets/logout.svg" 
                      class="img"
                    >
                  </button>
                </div>
              </div>
            </div>
            <div 
              v-if="$route.path == '/profile/transactions'"
              class="profile-content profile-content-transactions"
            >
              <div class="desc main-desc">
                <b>{{ $t('Payment hystory') }}</b>
              </div>
              <div 
                v-if="$parent.depositHistory"
                class="profile-table" 
              >
                <div class="profile-table-wrapper profile-form-wrapper">
                  <div class="table-container">
                    <table>
                      <thead>
                        <tr>
                          <td>{{ $t('ID') }}</td>
                          <td>{{ $t('Date') }}</td>
                          <td>{{ $t('Type') }}</td>
                          <td>{{ $t('Status') }}</td>
                          <td>{{ $t('Total') }}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr 
                          v-for="(item,i) in $parent.depositHistory" 
                          :key="i"
                        >
                          <td>#{{ item.id }}</td>
                          <td>{{ $parent.formatDate(item.created_at) }}</td>
                          <td>{{ $t('Top up balance') }}</td>
                          <td 
                            v-if="item.status == 'Accepted'" 
                            class="green"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td 
                            v-else-if="item.status == 'Declined'" 
                            class="red"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td v-else>
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td class="desc">
                            <b>
                              {{ $parent.currency }} {{ item.sum }}
                            </b>  
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div 
                    id="pagination" 
                    class="pagination" 
                  />
                </div>
              </div>
            </div>
            <div 
              v-if="$route.path == '/profile/orders'"
              class="profile-content profile-content-orders"
            >
              <div class="desc main-desc">
                <b>{{ $t('Order history') }}</b>
              </div>
              <div 
                v-if="$parent.orderHistory" 
                class="profile-table" 
              >
                <div class="profile-table-wrapper profile-form-wrapper">
                  <div class="table-container">
                    <table>
                      <thead>
                        <tr>
                          <td>{{ $t('ID') }}</td>
                          <td>{{ $t('Date') }}</td>
                          <td>{{ $t('Skin name') }}</td>
                          <td>{{ $t('Count') }}</td>
                          <td>{{ $t('Status') }}</td>
                          <td>{{ $t('Total') }}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr 
                          v-for="(item,i) in $parent.orderHistory" 
                          :key="i"
                        >
                          <td>{{ item.item_id }}</td>
                          <td>{{ $parent.formatDate(item.created_at) }}</td>
                          <td>
                            <img 
                              :src="$parent.imgDomain + item.img_url" 
                              class="img"
                            >
                            {{ item.title }}
                          </td>
                          <td class="desc">
                            {{ item.count }}
                          </td>
                          <td 
                            v-if="item.status == 'Accepted'" 
                            class="green"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td 
                            v-else-if="item.status == 'Declined'" 
                            class="red"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td v-else>
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td class="desc">
                            <b>
                              {{ $parent.currency }} {{ item.sum }}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div 
                    id="pagination"
                    class="pagination" 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Profile',
  components: {
  },
  props: {
    isAuth: {
			type: Boolean,
			required: true
		},
    appBalance: {
			type: [Number, String],
			required: true
		}
  },
  data: function() {
    return {
      name: '',
      surname: '',
      email: '',
      phone: '',
      country: '',
      selectedCountry: '',
      countryId: '',
      city: '',
      address: '',
      zipCode: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      balance: '0.00',
      countryOptions: [],
      steamId: '',
      steamLink: '',
      successSteamLink: '',
      errorSteamLink: ''
    }
  },
  watch: {
    appBalance() {
      this.getProfile();
		},
  },
  mounted() {
    this.getCounties();
  },
  methods: {
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
          this.getProfile();
      })
      .catch((res) => {
      })
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
        this.balance = res.data.balance;
        this.$emit('setAppBalance', res.data.balance);
        this.city = res.data.city;
        this.zipCode = res.data.zip;
        this.address = res.data.address;
        let countryId = this.countryOptions.find(obj => obj.id == res.data.country);
        this.selectedCountry = countryId;
        this.steamId = res.data.steamId;
        this.steamLink = this.profileData.steam_profile_url;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    updateSteamLink() {
      let steamLink = {
        "url": this.steamLink,
      }
      this.$http.put(process.env.VUE_APP_API + 'user/steam-profile-url', steamLink)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successSteamLink = 'Success';
          setTimeout(() => {
            this.successSteamLink = '';
          }, 3000);
        }
      })
      .catch((res) => {
        let self = this;
        this.errorSteamLink = res.response.data.message;
        setTimeout(() => {
          self.errorSteamLink = '';
        }, 3000);
      })
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "country": this.selectedCountry.id,
        "city": this.city,
        "postCode": this.zipCode,
        "address": this.address
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    }
  }
}
</script>